import React, { useState, useContext, useEffect, useRef } from 'react';
import { CartContext } from '../../context/cartContext';
import '../../styles/checkout/checkout.css';
import PagoPayPal from './pagoPayPal';
import useCurrencyConverter from '../../hooks/useCurrencyConverter';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import DetailBreadCrumb from '../BreadCrumb';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { ToastContainer, toast } from 'react-toastify';
import ImageModalComponent from './ImageModalComponent';
import { Link } from 'react-router-dom';

const Checkout = () => {
  const { cart, partialCost, partialCostUSD } = useContext(CartContext);
  const [step, setStep] = useState(1);
  const [usd, setUSD] = useState(null);
  const [ars, setARS] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    personalInfo: {},
    cart: cart,
  });
  const { conversionRate, convertCurrency } = useCurrencyConverter();
  const [isDataComplete, setIsDataComplete] = useState(false);
  const [conversion, setConversion] = useState('ARS');
  const [isDataStepTwo, setIsDataStepTwo] = useState(false);
  const [currency, setCurrency] = useState('ARS');
  const stepperRef = useRef(null);
  const [paymentMethod, setPaymentMethod] = useState('MercadoPagoWallet');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    const fetchConversion = async () => {
      try {
        const costARS = partialCost(cart);
        const costUSD = partialCostUSD(cart)
        setARS(costARS);
        setUSD(costUSD);
      } catch (error) {
        toast.error('Error al obtener la tasa de conversión:');
      }
    };

    fetchConversion();
  }, [cart, convertCurrency, partialCost, conversionRate]);


  const handleNextStep = (fieldName) => {
    if (step === 1 && !formData.email) {
      toast.error('Por favor, ingresa tu correo electrónico.');
      return;
    } else if (fieldName === 'pay') {
  
        if(isDataComplete === false){
          const { Nombre, Apellido, DNI, CodArea, Telefono } = formData.personalInfo;
          if (!Nombre || !Apellido || !DNI || !CodArea || !Telefono) {
            toast.error('Por favor, completa todos los campos de identificación.');
            return;
          }
          setIsDataComplete(true);
        }
    }

    if (formData[fieldName] !== '') {
      setFormData({ ...formData, [fieldName]: formData[fieldName] });
      setStep(step + 1);
    }

    if (step === 2) {
      setIsDataStepTwo(true);
    }

    stepperRef.current.nextCallback();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Por favor, ingresá un correo válido.');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === 'email') {
      validateEmail(value); // Valida el email cada vez que cambia
    }
  };

  const handleConversion = () => {
    setConversion(conversion === 'ARS' ? 'USD' : 'ARS');
  };

  return (
    <section>

      <div className='filterContainerDetail'>
        <DetailBreadCrumb title={'Pago'} />
      </div>

      <div className='checkout-container'>

        <div className='form-container'>
          <div>
            <h3>Detalles del pago</h3>
            <p>Una vez realizado el pago podrás descargar los productos. Además se enviará un link de descarga al correo que especifiques a continuación. <Link to="/faqs"><em className='em-content'>Leer más detalles</em></Link></p>
          </div>

          <div className="card">
            <Stepper ref={stepperRef} style={{ flexBasis: 'fit-content' }} orientation="vertical">
              <StepperPanel header="MAIL" >
                <div className="flex flex-column h-12rem">
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                    <div className={`checkout-section ${step >= 1 ? 'open' : 'closed'}`}>
                      {step >= 1 ? (
                        <div className='input-group'>
                          <label>Email:</label>
                          <input
                            type='email'
                            value={formData.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                          />
                           {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        </div>
                      ) : (
                        <p className={`small-text ${formData.email ? 'visible' : 'hidden'}`}>
                          Email: {formData.email}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex py-4">
                  <Button
                    label="Siguiente"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    style={{ backgroundColor: '#0056b3', border: 'none', borderRadius: '0.3rem' }}
                    onClick={() => {
                      handleNextStep('email');
                    }}
                  />
                </div>
              </StepperPanel>

              <StepperPanel header="METODO DE PAGO">
                <div className="payment-container">
                  <h5 className="title-checkout">Seleccioná la moneda para pagar</h5>

                  <p className='local-money'>Si querés pagar con la moneda de tu país seleccioná <em>USD</em>, PayPal realiza la conversión por vos. No necesitás pagar con dólares obligatoriamente ni contar con saldo en tu cuenta.</p>
                  
                  <div className="currency-buttons">
                    <button className={`btns-currency ${currency === 'ARS' ? 'selected' : ''}`} onClick={() => setCurrency('ARS')}>ARS</button>
                    <button className={`btns-currency btn-display-none ${currency === 'USD' ? 'selected' : ''}`} onClick={() => setCurrency('USD')}>USD</button>
                  </div>

                  {currency === 'USD' && (
                    <div className="payment-method-select">
                      <div className='selectedMethodLabel'>

                      </div>
                      <div>

                        <label>
                          <input
                            type="radio"
                            name="payment-method"
                            value="MercadoPagoWallet"
                            checked={paymentMethod === 'MercadoPagoWallet'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          />
                          
                          <img src="paypalLG.jpg" alt="MercadoPago Logo" className="payment-logo"/>
                        </label>
                      </div>
                    </div>
                  )}

                  {currency === 'ARS' && (
                    <div className="payment-method-select">
                      <div className='selectedMethodLabel'>
           
                      </div>
                      <div className='container-selected'>
                        <label>
                          <input
                            type="radio"
                            name="payment-method"
                            value="MercadoPagoWallet"
                            checked={paymentMethod === 'MercadoPagoWallet'}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            className="hidden-radio"
                          />
                          <ImageModalComponent/>
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex py-4 gap-2">
                  <Button
                    label="Siguiente"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    style={{ backgroundColor: '#0056b3', border: 'none', borderRadius: '0.3rem' }}
                    onClick={() => {
                      handleNextStep('personalInfo');
                    }}
                  />
                </div>
              </StepperPanel>

              {currency === 'ARS' && (
              <StepperPanel header="IDENTIFICACIÓN">
                <div className="flex flex-column h-12rem">
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                    <div className={`checkout-section ${step >= 2 ? 'open' : 'closed'}`}>
                      <p> *Por favor, asegúrate de que los datos personales coincidan con los del método de pago.</p>
                      {step >= 2 ? (
                        <>
                          {['Nombre', 'Apellido', 'DNI', 'CodArea', 'Telefono'].map((field) => (
                            <div className='input-group' key={field}>
                              <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                              <input
                                type='text'
                                value={formData.personalInfo[field] || ''}
                                onChange={(e) => handleChange('personalInfo', { ...formData.personalInfo, [field]: e.target.value })}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>
                          {Object.entries(formData.personalInfo).map(([key, value]) => (
                            <div key={key} className='containerP'>
                              <p className={`small-text ${value ? 'visible' : 'hidden'}`}>
                                {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex py-4 gap-2">
                  <Button
                    label="Siguiente"
                    icon="pi pi-arrow-right"
                    iconPos="right"
                    style={{ backgroundColor: '#0056b3', border: 'none', borderRadius: '0.3rem' }}
                    onClick={() => {
                      handleNextStep('pay');
                    }}
                  />
                </div>
              </StepperPanel>
              )}

              <StepperPanel header="PAGO">
                <div className="flex flex-column h-12rem">
                  <div className="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                    <div className={`checkout-section ${step >= 3 ? 'open' : 'closed'}`}>
                      <PagoPayPal currencySelected={currency} paymentMethodSelected={paymentMethod} emailSend={formData.email} clientData={formData.personalInfo} />
                    </div>
                  </div>
                </div>

              </StepperPanel>
            </Stepper>
          </div>
        </div>

        <div className="instances-container">
          <div className='father-instance'>
            <div>
              <h3>Artículos en tu carrito</h3>
            </div>
            {cart.map((item) => (
              <div key={item.productID} className="instance">
                <div>
                  <img src={`${item.productID.images[0]}`} alt={`Imagen del producto ${item.productID.images[0]}`} />
                </div>

                <div>
                  <p>{item.productID.title}</p>
                </div>

                <div>
                  <p>${item.productID.priceARS} ARS</p>
                  <p>${item.productID.priceUSD} USD</p>
                </div>
              </div>
            ))}
          </div>

          <div className="total-price">

            <div className='price-conversion'>

              <div>
                <h3>TOTAL</h3>
              </div>

              <div className='prices-content'>
                <h3>$ {ars} ARS</h3>
                <h3>$ {usd} USD</h3>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Checkout;
