import React, { useState, useEffect } from "react";
import '../../styles/admin/modifyProduct.css';
import useUpdateProduct from "../../hooks/UseUpdateProduct";

const ModifyProduct = ({ product, onSave, onCancel }) => {
    const { updateProduct, loading, error } = useUpdateProduct();

    const [title, setTitle] = useState(product.title);
    const [priceARS, setPriceARS] = useState(product.priceARS);
    const [priceUSD, setPriceUSD] = useState(product.priceUSD);
    const [description, setDescription] = useState(product.description);
    const [category, setCategory] = useState(product.category);
    const [images, setImages] = useState(product.images);
    const [newImages, setNewImages] = useState([]);
    const [fileadj, setFileadj] = useState(product.fileadj || []);
    const [mercadoLibreUrl, setMercadoLibreLink] = useState(product.mercadoLibreUrl || '');
    const [position, setPosition] = useState(product.position || null);
    const [newFileAdjUrl, setNewFileAdjUrl] = useState('');
    const [newFileAdjName, setNewFileAdjName] = useState('');

    useEffect(() => {
        setTitle(product.title);
        setPriceARS(product.priceARS);
        setPriceUSD(product.priceUSD);
        setDescription(product.description);
        setCategory(product.category);
        setImages(product.images);
        setFileadj(product.fileadj || []);
        setMercadoLibreLink(product.mercadoLibreUrl || '');
        setPosition(product.position || null);
    }, [product]);

    const handleUpdate = async () => {
        const updatedProduct = {
            title,
            priceARS,
            priceUSD,
            description,
            category,
            images: [...images, ...newImages.map(file => URL.createObjectURL(file))], // Adiciona nuevas imágenes
            fileadj,
            mercadoLibreUrl,
            position
        };

        const formData = new FormData();
        newImages.forEach((image) => {
            formData.append('images', image);
        });
        formData.append('productData', JSON.stringify(updatedProduct));

        // Enviar la solicitud para actualizar el producto
        await updateProduct(product._id, formData);

        if (!loading && !error) {
            onSave(updatedProduct);
        }
    };

    const handleInputChange = (e) => {
        const files = Array.from(e.target.files);
        setNewImages(files);
    };

    const handleRemoveItem = (index, type) => {
        if (type === 'image') {
            setImages(images.filter((_, i) => i !== index));
        } else if (type === 'file') {
            setFileadj(fileadj.filter((_, i) => i !== index));
        }
    };

    const handleReorderItem = (index, direction, type) => {
        if (type === 'image') {
            const newImages = [...images];
            const [movedItem] = newImages.splice(index, 1);
            newImages.splice(index + direction, 0, movedItem);
            setImages(newImages);
        } else if (type === 'file') {
            const newFiles = [...fileadj];
            const [movedItem] = newFiles.splice(index, 1);
            newFiles.splice(index + direction, 0, movedItem);
            setFileadj(newFiles);
        }
    };

    const handleAddFileAdj = () => {
        if (newFileAdjUrl && newFileAdjName) {
            const newFile = { url: newFileAdjUrl, name: newFileAdjName };
            setFileadj([...fileadj, newFile]);
            setNewFileAdjUrl('');
            setNewFileAdjName('');
        }
    };

    return (
        <div className="modify-product-container">
            <h3>Modificar producto</h3>
            <div className="form-group">
                <label>Título:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Precio ARS:</label>
                <input
                    type="number"
                    value={priceARS}
                    onChange={(e) => setPriceARS(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Precio USD:</label>
                <input
                    type="number"
                    value={priceUSD}
                    onChange={(e) => setPriceUSD(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Descripción:</label>
                <textarea
                    value={description}
                    rows={15}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Categoría:</label>
                <input
                    type="text"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label>Link de Mercado Libre:</label>
                <input
                    type="url"
                    value={mercadoLibreUrl}
                    onChange={(e) => setMercadoLibreLink(e.target.value)}
                    placeholder="https://articulo.mercadolibre.com.ar/MLA-..."
                />
            </div>
            <div className="form-group">
                <label>Posición:</label>
                <input
                    type="number"
                    value={position || ''}
                    onChange={(e) => setPosition(e.target.value ? parseInt(e.target.value, 10) : null)}
                    placeholder="Ingresa la posición en número."
                />
            </div>

            <div className="form-group">
                <label>Imágenes actuales:</label>
                <ul>
                    {images.map((image, index) => (
                        <li key={index}>
                            <img src={image} alt={`Imagen ${index}`} style={{ width: '100px', height: '100px' }} />
                            <button
                                onClick={() => handleReorderItem(index, -1, 'image')}
                                disabled={index === 0}
                            >
                                ↑
                            </button>
                            <button
                                onClick={() => handleReorderItem(index, 1, 'image')}
                                disabled={index === images.length - 1}
                            >
                                ↓
                            </button>
                            <button onClick={() => handleRemoveItem(index, 'image')}>
                                Eliminar
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="add-item-form">
                    <label className="form-label" htmlFor="images">Agregar Imágenes:</label>
                    <input
                        className="form-input"
                        type="file"
                        id="images"
                        name="images"
                        onChange={handleInputChange}
                        accept="image/*"
                        multiple
                        required
                    />
                </div>
            </div>

            <div className="form-group">
                <div className="file-adj-container">
                    <div className="file-adj-input-group">
                    <label className="form-label" htmlFor="fileadjUrl">Agregar URL del archivo:</label>
                    <input
                        type="url"
                        value={newFileAdjUrl}
                        onChange={(e) => setNewFileAdjUrl(e.target.value)}
                        placeholder="https://url-drive"
                    />
                    <label className="form-label" htmlFor="fileadjName">Nombre del archivo:</label>
                    <input
                        type="text"
                        value={newFileAdjName}
                        onChange={(e) => setNewFileAdjName(e.target.value)}
                        placeholder="Nombre del archivo"
                    />
                        <button type="button" onClick={handleAddFileAdj} className="add-url-button">
                            Agregar
                        </button>
                    </div>
                    
                    <ul className="file-adj-list">
                        {fileadj.map((urlObj, index) => (
                            <li key={index} className="file-adj-item">
                                <a 
                                    href={urlObj.url ? urlObj.url : urlObj} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    {urlObj.url ? urlObj.url : urlObj}
                                </a>
                                <button onClick={() => handleRemoveItem(index, 'file')} className="remove-file-button">
                                    Eliminar
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="button-group">
                <button onClick={handleUpdate} disabled={loading}>
                    {loading ? "Guardando..." : "Guardar cambios"}
                </button>
                <button onClick={onCancel}>Cancelar</button>
            </div>
            {error && <p className="error">Error: {error}</p>}
        </div>
    );
};

export default ModifyProduct;
