import React, { useState } from 'react';
import '../../styles/FAQs/faqs.css';
import DetailBreadCrumb from '../BreadCrumb.jsx';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: '¿Cuánto tiempo demora en llegarme el producto?',
      answer: 'El producto te llega al instante. '
    },
    {
      question: '¿Cómo lo recibo?',
      answer: 'Una vez realizada la compra tendrás acceso a la descarga del producto que hayas seleccionado. Además se te enviará al correo proporcionado para que puedas descargarlo cuantas veces quieras.'
    },
    {
      question: '¿Cuánto tiempo tengo acceso?',
      answer: 'Nosotros garantizamos mínimo 60 días de corrido. Suele durar mucho más antes de que el enlace expire. Si por alguna razón no pudieras acceder y aún no se cumplieron los 60 días te enviamos el producto nuevamente. Pasados los 60 días no volvemos a enviar los productos.'
    },
    {
      question: '¿Qué pasa si tengo dudas o consultas sobre el producto que compré?',
      answer: (
        <>
          Ofrecemos soporte todos los días en todos nuestros medios de comunicación. Recomendamos {' '}
          <a className='wpp-link' href='https://wa.me/message/LCVK2PT67LHNG1' target='_blank' rel='noopener noreferrer'>
          nuestro WhatsApp
          </a>.
        </>
      )
    },
    {
      question: 'Compré creyendo que el producto era físico, no sabía que era digital. ¿Puedo devolverlo?',
      answer: 'Debido a que nuestros productos son no-tangibles (digitales) no realizamos reembolsos. El comprador tiene la responsabilidad de informarse las características antes de comprarlo. Por eso pedimos que leas cuidadosamente la descripción antes de realizar una compra. Solo se hacen excepciones de esta regla cuando la descripción no es clara o no se ajusta a lo entregado.'
    }
  ];

  return (
    <section>
    <div className='filterContainer'>
      <DetailBreadCrumb categoryName={'Preguntas frecuentes'} title={''} />
    </div>

    <div className='faqs-container'>
      <h2 className='faqs-title'>Preguntas Frecuentes</h2>

      <div className="faqs-list">
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div 
                className="faq-question" 
                onClick={() => toggleAnswer(index)}
              >
                <h4>{faq.question}</h4>
                <span className="toggle-icon">
                  {activeIndex === index ? '−' : '+'}
                </span>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>

      <div className='faq-item'>
        <img 
            src={`logofaqs.jpeg`} 
            alt="Logo de la empresa" 
            className="company-logo"
          />
          <p>&copy; {new Date().getFullYear()} ALFIL DIGITAL.</p>
        </div>
    </div>
    </section>

  );
};

export default FAQs;