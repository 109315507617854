import React from 'react';
import '../../styles/admin/transactions.css';
import { GrClear } from "react-icons/gr";

const TransactionList = ({ transactions, onDeleteTransaction }) => {
  const getPaymentMethod = (status) => {
    if (status === 'accredited') return 'MercadoPago';
    if (status === 'COMPLETED') return 'PayPal';
    return 'Desconocido';
  };

  const getStatusLabel = (status) => {
    if (status === 'accredited' || status === 'COMPLETED') return 'Completado';
    if (status === 'pending') return 'Pendiente';
    return 'Desconocido';
  };

  const getProductNames = (cart) => {
    return cart.map((item) => item.name).join(', ');
  };

  return (
    <div className="transaction-list">
      <h2>Lista de Transacciones</h2>
      <table>
        <thead>
          <tr>
            <th>Productos</th>
            <th>Estado</th>
            <th>Email</th>
            <th>Método de Pago</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Cód. Área</th>
            <th>Teléfono</th>
            <th>DNI</th>
            <th>Total</th>
            <th>Fecha</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactions.slice().reverse().map((transaction) => (
            <tr key={transaction.id}>
              <td className='td-products'>{getProductNames(transaction.cart)}</td>
              <td>{getStatusLabel(transaction.status)}</td>
              <td>{transaction.email}</td>
              <td>{getPaymentMethod(transaction.status)}</td>
              <td>{transaction.clientData?.Nombre || 'N/A'}</td>
              <td>{transaction.clientData?.Apellido || 'N/A'}</td>
              <td>{transaction.clientData?.CodArea || 'N/A'}</td>
              <td>{transaction.clientData?.Telefono || 'N/A'}</td>
              <td>{transaction.clientData?.DNI || 'N/A'}</td>
              <td>{transaction.total !== undefined ? transaction.total : 'N/A'}</td>
              <td>{transaction.createdAt !== undefined ? transaction.createdAt : 'N/A'}</td>
              <td>
              <GrClear className="delete-icon" onClick={() => onDeleteTransaction(transaction.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionList;