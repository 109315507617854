import React, { useEffect } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import ClipLoader from 'react-spinners/ClipLoader';

const MercadoPagoWallet = ({ preferenceId, loading }) => {
  useEffect(() => {
    initMercadoPago('APP_USR-1c94013b-bd93-41ca-9bc3-328a909ac223', { locale: 'es-AR' });
  }, []);

  const handlePaymentClick = () => {
    const walletElement = document.getElementById("wallet_container");
    if (walletElement) {
      walletElement.click();
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader size={50} color={"#123abc"} loading={true} />
        </div>
      ) : (
        <button 
          id="wallet_container" 
          className="payment-btn mercado-pago-btn" 
          onClick={handlePaymentClick}
        >
          <Wallet 
            initialization={{ preferenceId, redirectMode: 'self' }} 
            customization={{ texts: { valueProp: 'smart_option' } }} 
          />
        </button>
      )}
    </div>
  );
};

export default MercadoPagoWallet;
