import React, { useEffect } from 'react';
import '../../styles/footer/footer.css';
import { Link, useLocation } from 'react-router-dom';
import { FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { SiMercadopago } from "react-icons/si";
const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="footer shapedividers_com-3946">
      <div className="footer-container">
        <div className="footer-section">
            <div className="logo">
              <Link to="/">
                <img className='logo-inver' src='logomain.jpeg' alt="ALF-DIG-LOGO" />
              </Link>
            </div>
        </div>
        <div className="footer-section utils-link">
          <h4>Enlaces útiles</h4>
          <ul>
            <li><Link to="/faqs">Sobre Nosotros</Link></li>
            <li><Link to="/faqs">Contacto</Link></li>
            <li><Link to="/faqs">Términos y Condiciones</Link></li>
            <li><Link to="/faqs">Política de Privacidad</Link></li>
          </ul>
        </div>
    
        <div className="footer-section">
          <h4>Seguinos</h4>
          <div className="social-icons-footer">
            <a className='icons-ml' href="https://mercadolibre.com.ar/perfil/ALFILDIGITAL" target="_blank" rel="noopener noreferrer">
              <SiMercadopago />
            </a>
            <a className='icons-wsp' href="https://wa.me/message/LCVK2PT67LHNG1" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
            <a className='icons-ig' href="https://instagram.com/alfil.digital/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a className='icons-tk' href="https://tiktok.com/@alfil_digital" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} ALFIL DIGITAL. Todos los derechos reservados.</p>
      </div>
      
    </footer>
  );
};

export default Footer;