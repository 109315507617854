import React, { useContext, useEffect, useState } from 'react';
import '../../styles/paypal.css';
import { CartContext } from '../../context/cartContext.js';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import MercadoPagoWallet from '../MercadoPago/AccountMoneyMP.jsx';
import ReactDOM from 'react-dom';

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const PagoPayPal = ({ currencySelected, paymentMethodSelected, emailSend, clientData }) => {
  const { cart, partialCostUSD, partialCost } = useContext(CartContext);
  const [preferenceId, setPreferenceId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderID, setOrderID] = useState(null); // Para almacenar el ID de la orden de PayPal
  const costUSD = partialCostUSD(cart);
  const costARS = partialCost(cart)

  useEffect(() => {

    const handleCheckoutARS = async () => {
      try {

        const createObjectFromCart = (cart) => {
          const mapeoCart = cart.map((item) => ({
            id: item.productID._id,
            title: item.productID.title,
            category_id: item.productID.category,
            description: item.productID.description,
            quantity: item.cant,
            unit_price: item.productID.priceARS,
          }));

          return {
            carrito: mapeoCart,
            items: cart,
            client: clientData,
            total: costARS
          };
        };

        const orderJson = createObjectFromCart(cart);

        const createOrderResponse = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/mercado-pago/create-order?emailSend=${emailSend}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orderJson),
        });

        if (!createOrderResponse.ok) {
          throw new Error('Error al crear la orden en Mercado Pago.');
        }

        const { id } = await createOrderResponse.json();
        setPreferenceId(id);
        setLoading(false);
      } catch (error) {
        console.error('Error al procesar el pago en Mercado Pago:', error);
      }
    };

    if (!preferenceId) {
      handleCheckoutARS();
    }
  }, [cart, preferenceId]);

  const createOrderPayPal = async () => {
    try {

      const requestBody = {
        amountUSD: costUSD,
        currency: currencySelected,
        emailSend: emailSend,
        carrito: cart,
      };

      const createOrderResponse = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/paypal/create-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!createOrderResponse.ok) {
        throw new Error('Error al crear la orden.');
      }

      const { payment_id } = await createOrderResponse.json();
      setOrderID(payment_id); 

      return payment_id ; 
    } catch (error) {
      console.error('Error al procesar el pago:', error);
    }
  };

  const onApprovePayPal = async (data) => {
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/paypal/capture-order/${data.orderID}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Error al capturar el pago en PayPal.');
      }

      const result = await response.json();

      window.open(`https://alfildigital.com.ar?payment_id=${result.id}`);
    } catch (error) {
      console.error('Error al capturar el pago:', error);
    }
  };

  const renderPaymentButtons = () => {
    switch (currencySelected) {
      case 'ARS':
        return (
          <section>
            {paymentMethodSelected === 'MercadoPagoWallet' && <MercadoPagoWallet preferenceId={preferenceId} loading={loading} />}
          </section>
        );
      case 'USD':
        return (
          <PayPalButton
            createOrder={createOrderPayPal}
            onApprove={onApprovePayPal}
            onError={(err) => console.error('Error en el pago con PayPal:', err)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderPaymentButtons()}
    </div>
  );
};

export default PagoPayPal;
