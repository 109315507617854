import { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config';

const useDeleteTransaction = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteTransaction = async (id) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/transactions?id=${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error al eliminar la transacción');
      }

      return true; // Devuelve true si la eliminación fue exitosa
    } catch (err) {
      setError(err.message);
      return false; // Devuelve false si hubo un error
    } finally {
      setLoading(false);
    }
  };

  return { deleteTransaction, loading, error };
};

export default useDeleteTransaction;