import { useState } from "react";
import { REACT_APP_HOST_HOOKS_DEV } from "../config/config";

const useUpdateProduct = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updatedProduct, setUpdatedProduct] = useState(null);

    const updateProduct = async (productId, formData) => {
        setLoading(true);
        setError(null);
    
        try {
            const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products/${productId}`, {
                method: 'PUT',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error(`Error al actualizar el producto: ${response.statusText}`);
            }
    
            const data = await response.json();
    
            setUpdatedProduct(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return { updateProduct, loading, error, updatedProduct };
};

export default useUpdateProduct;
