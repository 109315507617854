import React, { useEffect, useState } from "react";
import TransactionList from "./TransactionList.jsx";
import useTransaction from "../../hooks/UseTransactions.js";
import useDeleteTransaction from "../../hooks/useDeleteTransaction.js";
import '../../styles/admin/transactions.css'

const Ticket = () => {
  const { transactions: initialTransactions, loading } = useTransaction();
  const [transactions, setTransactions] = useState(initialTransactions);
  const { deleteTransaction } = useDeleteTransaction();

  useEffect(() => {
    if (!loading) {
      setTransactions(initialTransactions);
    }
  }, [initialTransactions, loading]);

  const handleDelete = async (id) => {
    const success = await deleteTransaction(id);
    if (success) {
      // Actualiza el estado de transacciones para eliminar la transacción eliminada
      setTransactions((prevTransactions) => 
        prevTransactions.filter(transaction => transaction.id !== id)
      );
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container-transaction">
      {transactions ? (
          <div className="container-child-transactions>">
          <TransactionList
            transactions={transactions}
            onDeleteTransaction={handleDelete}
          />
        </div>
      ) : (
        <div>No hay transacciones disponibles.</div>
      )}
    </div>
  );
};

export default Ticket;