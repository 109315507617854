import React from "react";
import Item from './Item.jsx';
import '../styles/itemList.css'

const ItemList = ({ productos }) => {

    if (!productos || (Array.isArray(productos) && productos.length === 0)) {
        return <div className="no-products">No hay productos disponibles</div>;
    }

    // Determinar el array de productos
    const payloadArray = Array.isArray(productos.payload) ? productos.payload : productos;

    return (
        <div className="padreCards">
            {payloadArray.map((producto) => (
                <Item key={producto.id} product={producto} />
            ))}
        </div>
    );
};

export default ItemList;