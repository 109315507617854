import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/SuccessPage.css';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const SuccessPage = ({ payment_id }) => {
  const [files, setFiles] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      const fetchData = async () => {
          try {
              setIsLoading(true);
              const URLS = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/checkout/generate-urls`, {
                  method: 'POST',
                  headers: {
                      'Payment-Id': payment_id,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ payment_id: payment_id }),
              });

              const data = await URLS.json();
              const encryptedUrls = data.prods;

              setFiles(encryptedUrls);

              setEmailSent(true);
              setIsLoading(false);
          } catch (error) {
              console.error('Error al obtener el carrito');
              setIsLoading(false);
          }
      };
      fetchData();
  }, [payment_id]);

  // Función para redirigir a la URL en lugar de descargar
  const handleRedirect = (fileUrl) => {
    window.open(fileUrl, '_self', 'noopener,noreferrer,width=1000,height=800');
    };

  return (
      <div className="success-page-container">
          <img 
              src={`logoalfilblanco.jpg`} 
              alt="Logo de la empresa" 
              className="company-logo"
          />
          <h2 className='h2-success'>¡Compra realizado con éxito!</h2>
          <p className='p-success'>¡Gracias por tu compra!</p>
          {isLoading ? (
              <p className='p-success'>Cargando archivos...</p>
          ) : (
              files.map((e, index) => (
                  <button 
                      key={index} 
                      onClick={() => handleRedirect(e.url)} 
                      className="download-button"
                  >
                      Acceder a {e.name}
                  </button>
              ))
          )}
          {emailSent &&       <p>
        <span role="img" aria-label="success" style={{ color: 'green', marginRight: '8px' }}>✔️</span>
        También te enviamos el enlace a tu correo.
      </p>}
          <p className='p-success'>Si hay algún problema comunicate por WhatsApp.</p>
          <Link to="/" className="home-link">
              Volver a Inicio
          </Link>
      </div>
  );
};
  export default SuccessPage;